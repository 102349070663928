<template>
  <div id="title" ref="title">
    <router-link to="/">Office Zero</router-link>
  </div>
  <div id="menu">
    <ul>
      <li><router-link to="./about">会社概要</router-link></li>
      <li><router-link to="./service">サービス</router-link></li>
      <li><router-link to="./recruit">採用情報</router-link></li>
      <li><router-link to="./privacy">プライバシーポリシー</router-link></li>
      <li><router-link to="./access">お問い合わせ</router-link></li>
    </ul>
  </div>
  <div id="main">
    <div class="center">
      <router-view></router-view>
    </div>
  </div>
  <div id="footer">
    <ul>
      <li>
        <router-link to="./about" v-on:click="gotoTop">会社概要</router-link>
      </li>
      <li>
        <router-link to="./service" v-on:click="gotoTop">サービス</router-link>
      </li>
      <li>
        <router-link to="./recruit" v-on:click="gotoTop">採用情報</router-link>
      </li>
      <li>
        <router-link to="./privacy" v-on:click="gotoTop"
          >プライバシーポリシー</router-link
        >
      </li>
      <li>
        <router-link to="./access" v-on:click="gotoTop"
          >お問い合わせ</router-link
        >
      </li>
    </ul>
  </div>
  <button
    v-bind:class="[isShowTopButton ? 'fadein' : 'fadeout', 'return-top-button']"
    v-on:click="gotoTop"
  >
    TOPへ戻る
  </button>
  <br />
  Copyright(C)1996-2022 OFFICE ZERO All Rights Reserved
  <br />
  <br />
</template>

<script>
export default {
  name: "App",
  components: {},
  data: function () {
    return {
      isShowTopButton: false,
    };
  },
  created: function () {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    /**
     * 一定値を超えるスクロールを行った時、TOPへ戻るボタンを表示する処理
     */
    handleScroll() {
      this.scrollY = window.scrollY;
      if (!this.isShowTopButton) {
        this.isShowTopButton = window.scrollY > 200;
      } else if (window.scrollY < 190) {
        this.isShowTopButton = false;
      }
    },
    /**
     * 画面最上部へ自動スクロールする処理
     */
    gotoTop() {
      const el = this.$refs["title"];
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@600&family=Roboto:wght@500&display=swap");

/* 全体設定 */
body {
  margin: 0;
  padding: 0;
  min-width: 500px;
}
@media (max-width: 950px) {
  body {
    margin: 0;
    padding: 0;
    min-width: 100vw;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
}
a:hover,
a:active,
a {
  color: #150952;
}

/* ヘッダ */
#title {
  font-size: 8em;
  text-align: left;
  background-attachment: fixed;
  background-image: url("@/assets/25234052_m.jpg");
  background-size: cover;
  height: 250px;
  font-family: "Bitter", serif;
  font-family: "Roboto", sans-serif;
  color: #4179b1;
}
@media (max-width: 950px) {
  #title {
    font-size: 3em;
    height: 100px;
  }
}

/* 共通ナビゲーター */
#menu,
#footer {
  min-width: 400px;
  background-color: #848f88;
}
#menu > ul,
#footer > ul {
  min-height: 50px;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#menu > ul > li,
#footer > ul > li {
  display: inline-block;
  min-width: 180px;
  min-height: 50px;
  height: 100%;
  transition: background-color 300ms;
}
@media (max-width: 950px) {
  #menu > ul > li,
  #footer > ul > li {
    font-size: 0.7em;
    min-width: calc(100vw / 5);
  }
}
#menu > ul > li:hover,
#footer > ul > li:hover {
  background-color: #e6aa98;
  transition: background-color 300ms;
}
#menu > ul > li > a,
#footer > ul > li > a {
  display: block;
  padding: 15px;
}

/* メインコンテンツ領域 */
#main {
  text-align: left;
}
#main > .center {
  margin: auto;
  min-width: 500px;
  max-width: 800px;
  width: 80%;
}
@media (max-width: 950px) {
  #main > .center {
    margin: auto;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}

/* TOPへ戻るボタン */
button.return-top-button {
  transition-property: visible;
  position: fixed;
  bottom: 100px;
  right: 30px;
  width: 100px;
  height: 100px;
  transition: 1s;
  opacity: 0.7;
}
button.return-top-button:hover {
  opacity: 1;
}
@media (max-width: 950px) {
  button.return-top-button {
  width: 70px;
  height: 50px;
  bottom: 20px;
  }
}

/** アニメーションとキーフレーム */
.fadein {
  animation-name: fadein;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeout {
  animation-name: fadeout;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
