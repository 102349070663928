<template>
  <table>
    <tbody>
      <tr>
        <td><h2>オフィスゼロ個人情報保護の方針 </h2></td>
      </tr>
      <tr>
        <td>
          OFFICE ZEROでは、個人情報の重要性を認識し、お客様個人に関する情報（以下「個人情報」）を取り扱っている管理責任者に適切な管理を行わせております。<br />
          お客様の個人情報を取得させていただく場合は、利用目的をできる限り特定するとともに、お客様との同意を得た上で必要最低限の個人情報を取得させていただきます。<br />
          また、必要の際は質問をさせていただく場合がございます。
        </td>
      </tr>
      <tr>
        <td><h2>第三者への提供について </h2></td>
      </tr>
      <tr>
        <td>
          OFFICE ZERO では、お客様より取得させていただいた個人情報を適切に管理し、お客様のご承諾がない限り個人情報は第三者へ開示・提供いたしません。<br />
          &nbsp;&nbsp;また、必要の際は質問をさせていただく場合がございます。
        </td>
      </tr>
      <tr>
        <td><h2>登録情報の訂正 </h2></td>
      </tr>
      <tr>
        <td>
          OFFICE ZEROでは、お客様の個人情報をできるだけ正確かつ最新の内容で管理しております。<br />
          変更・訂正・削除などは以下にて受け付けております。<br /><br />
          &nbsp;〒532-0011&nbsp;大阪市淀川区西中島4-6-15&nbsp;ミツフ第3ビル6F<br />
          &nbsp;TEL &nbsp;06-6304-2501(平日9：00～18：00)<br />
          &nbsp;MAIL&nbsp;<a href="mailto:info@office-zero.co.jp"
            >info@office-zero.co.jp</a
          >
        </td>
      </tr>
      <tr>
        <td>
          <h2>IPアドレス・クッキーについて</h2>
        </td>
      </tr>
      <tr>
        <td>
          OFFICE ZEROでは、ログに記録されたお客様のIPアドレスを以下の理由でご利用させていただく場合がございます。<br />
          &nbsp;・サーバーで発生した問題の原因を突き止め解決するため<br />
          &nbsp;・サイト管理のため<br />
          また、必要の際は質問をさせていただく場合がございます。
        </td>
      </tr>
      <tr>
        <td>
          <h2>プライバシーポリシーに関するお問い合わせ</h2>
        </td>
      </tr>
      <tr>
        <td>
          &nbsp;&nbsp;〒532-0011&nbsp;大阪市淀川区西中島4-6-15&nbsp;ミツフ第3ビル6F<br />
          &nbsp;&nbsp;TEL &nbsp;06-6304-2501(平日9：00～18：00)<br />
          &nbsp;&nbsp;MAIL&nbsp;<a href="mailto:info@office-zero.co.jp">info@office-zero.co.jp</a><br /><br />
        </td>
      </tr>

      <tr>
        <td align="right">OFFICE ZERO 代表取締役&nbsp;仲谷&nbsp;敬正</td>
      </tr>
      <tr>
        <td align="right">最終更新日&nbsp;2009/06/15</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "PrivacyPage",
  props: {
    msg: String,
  },
  setup() {
  },
};
</script>