<template>
  <h1 class="fadein fadein-delay1">事業内容</h1>
  <h2 class="fadein fadein-delay1"><span>システム設計・開発</span></h2>
  <h2 class="management fadein fadein-delay2"><span>運用管理</span></h2>

  <h1 class="fadein fadein-delay3">サービス</h1>
  <h2 class="mail fadein fadein-delay3">
    <router-link to="/service#mail">メール配信</router-link>
  </h2>
  <h2 class="rental fadein fadein-delay4">
    <router-link to="/service#rental">レンタルサーバー</router-link>
  </h2>
  <h2 class="domain fadein fadein-delay5">
    <router-link to="/service#domain">ドメイン取得代行</router-link>
  </h2>
</template>

<script>
export default {
  name: "IndexPage",
  props: {
    msg: String,
  },
  setup() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
html {
  scroll-behavior: smooth;
}

h2 {
  background-image: url("@/assets/25234052_m.jpg");
  height: 150px;
  background-size: cover;
  background-position-y: 200px;
  text-shadow: 2px 2px 3px #fff;
}
h2 > a,
h2 > span {
  color: #150952;
  display: block;
  padding:10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
}
h2.management {
  background-image: url("@/assets/24970236_m.jpg");
  background-position-y: 200px;
}
h2.mail {
  background-image: url("@/assets/25096211_m.jpg");
  background-position-y: 200px;
}
h2.rental {
  background-image: url("@/assets/25014573_m.jpg");
  background-position-y: 200px;
}
h2.domain {
  background-image: url("@/assets/24955416_m.jpg");
  background-position-y: 200px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

/** アニメーションとキーフレーム */
.fadein {
  position: relative;
  animation-name: fadein;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}
.fadein-delay1 {
  animation-delay: 0.2s;
} 
.fadein-delay2 {
  animation-delay: 0.4s;
} 
.fadein-delay3 {
  animation-delay: 0.6s;
} 
.fadein-delay4 {
  animation-delay: 0.8s;
} 
.fadein-delay5 {
  animation-delay: 1s;
} 
@keyframes fadein {
  0% {
    opacity: 0;
    left: -70px;
  }
  100% {
    opacity: 1;
    left: 0px;
  }
}
</style>
