<template>NotFound</template>

<script>
export default {
  name: 'NotFoundPage',
  props: {
    msg: String
  },
  setup() {
  },
}
</script>