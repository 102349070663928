<template>
  <table>
    <tbody>
      <tr>
        <td><h2>職種</h2></td>
      </tr>
      <tr>
        <td>PG / SE</td>
      </tr>
      <tr>
        <td><h2>業務内容</h2></td>
      </tr>
      <tr>
        <td>
          ■システム開発<br /><br />
          &nbsp;・ビジネスアプリケーション&nbsp;:&nbsp;設計／開発<br /><br />
          &nbsp;・WEBアプリケーション&nbsp;:&nbsp;設計／開発<br /><br />
          <br /><br />
          ■運営管理<br /><br />
          &nbsp;・ビジネスアプリケーション&nbsp;:&nbsp;運用保守／システムメンテナンス<br /><br />
          &nbsp;・WEBアプリケーション&nbsp;:&nbsp;運用保守／システムメンテナンス<br /><br />
          <br /><br />
          ■サービス<br /><br />
          &nbsp;・<a href="service.php#mail">メール配信サービス</a><br /><br />
          &nbsp;・<a href="service.php#rent"
            >サーバーレンタル（法人向け）サービス</a
          ><br /><br />
          &nbsp;・<a href="service.php#dom">ドメイン取得代行サービス</a
          ><br /><br />
        </td>
      </tr>
      <tr>
        <td><h2>資格</h2></td>
      </tr>
      <tr>
        <td>
          18才以上。<br /><br />
          未経験者可。<br /><br />
          経験者歓迎いたします。<br /><br />
          &nbsp;・Linux、Unixに詳しい方歓迎。<br /><br />
          &nbsp;・言語不問、ブランクある方も可、契約社員希望者もＯＫ。<br /><br />
          学生アルバイト可（未経験者不可&nbsp;/&nbsp;時間・期間応相談）。<br /><br />
        </td>
      </tr>
      <tr>
        <td><h2>勤務地</h2></td>
      </tr>
      <tr>
        <td>大阪市淀川区西中島4-6-15 ミツフ第3ビル 6F</td>
      </tr>
      <tr>
        <td><h2>勤務時間</h2></td>
      </tr>
      <tr>
        <td>09:00 ～ 18:00（実働８時間・フレックス制あり）<br /></td>
      </tr>
      <tr>
        <td><h2>休日</h2></td>
      </tr>
      <tr>
        <td>完全週休2日制（土・日）、祝日、夏期、年末年始、有給休暇<br /></td>
      </tr>
      <tr>
        <td><h2>待遇</h2></td>
      </tr>
      <tr>
        <td>
          家族・住宅（自宅通勤者除く）手当て<br /><br />
          各種社会保険完備、交通費支給、退職金<br />
        </td>
      </tr>
      <tr>
        <td><h2>応募</h2></td>
      </tr>
      <tr>
        <td>
          お電話、または<a href="mailto:info@office-zero.co.jp">Ｅメール</a
          >で応募下さい。<br /><br />
          その後、履歴書（写真貼付）・職務経歴書を郵送下さい。<br /><br />
          後日、改めて面接の詳細等につきましてご連絡いたします。<br /><br />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "RecruitPage",
  props: {
    msg: String,
  },
  setup() {
  },
};
</script>