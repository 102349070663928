<template>
  <table>
    <tbody>
      <tr>
        <th>社名</th>
        <td>有限会社OFFICE ZERO</td>
      </tr>
      <tr>
        <th>設立</th>
        <td>平成8年8月8日</td>
      </tr>
      <tr>
        <th>従業員数</th>
        <td>8名</td>
      </tr>
      <tr>
        <th>代表取締役</th>
        <td>仲谷&nbsp;敬正</td>
      </tr>
      <tr>
        <th valign="top">事業内容</th>
        <td>
          ■システム開発<br />
          &nbsp;・ビジネスアプリケーション&nbsp;:&nbsp;コンサルティング／設計／開発<br />
          &nbsp;・WEBアプリケーション&nbsp;:&nbsp;コンサルティング／設計／開発<br />
          <br />
          ■運営管理<br />
          &nbsp;・ビジネスアプリケーション&nbsp;:&nbsp;運用保守／システムメンテナンス<br />
          &nbsp;・WEBアプリケーション&nbsp;:&nbsp;運用保守／システムメンテナンス<br />
          <br />
          ■サービス<br />
          &nbsp;・<a href="service.php#mail">メール配信サービス</a><br />
          &nbsp;・<a href="service.php#rent"
            >サーバーレンタル（法人向け）サービス</a
          ><br />
          &nbsp;・<a href="service.php#dom">ドメイン取得代行サービス</a><br />
        </td>
      </tr>

      <tr>
        <th valign="top">事業実績</th>
        <td>
          ■WEBアプリケーション開発<br />
          &nbsp;・医療業向け&nbsp;:&nbsp;WEB情報ネットワークシステム<br />
          &nbsp;・製造業向け&nbsp;:&nbsp;WEB部品管理システム<br />
          &nbsp;・小売業向け&nbsp;:&nbsp;WEBサーバアプリケーション<br />
          &nbsp;・サービス業向け&nbsp;:&nbsp;メール自動配信システム<br />
          &nbsp;・家電メーカー向け&nbsp;:&nbsp;社内統合システム<br />
          <br />
          ■ビジネスアプリケーション開発<br />
          &nbsp;・複数業種向け&nbsp;:&nbsp;販売／在庫管理システム<br />
          &nbsp;・建設／製造業向け&nbsp;:&nbsp;見積作成管理システム<br />
          &nbsp;・乗馬クラブ／スポーツジム向け&nbsp;:&nbsp;会員管理システム<br />
          &nbsp;・公共団体向け&nbsp;:&nbsp;会計管理システム<br />
          &nbsp;・サービス業向け&nbsp;:&nbsp;カードポイント管理システム<br />
          &nbsp;・FC業向け&nbsp;:&nbsp;流通管理システム<br />
          &nbsp;・サービス業向け&nbsp;:&nbsp;POSデータ集計システム<br />
          &nbsp;・道路公団向け&nbsp;:&nbsp;GIS情報管理システム<br />
          &nbsp;・サービス業向け&nbsp;:&nbsp;GIS電話配線管理システム<br />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "AboutPage",
  props: {
    msg: String,
  },
};
</script>

<style scoped>
  th {
    width: 20%;
  }
</style>