<template>
  <h2>アクセス</h2>
  <table>
    <tbody>
      <tr>
        <th valign="top">本社所在地</th>
        <td>
          〒532-0011&nbsp;&nbsp;大阪市&nbsp;淀川区&nbsp;西中島&nbsp;4-6-15&nbsp;ミツフ第３ビル６Ｆ<br />
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <iframe
            class="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3279.143888431494!2d135.4961205!3d34.72676729999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e4301a6ae6a1%3A0x2662c352b9b1088!2z44CSNTMyLTAwMTEg5aSn6Ziq5bqc5aSn6Ziq5biC5reA5bed5Yy66KW_5Lit5bO277yU5LiB55uu77yW4oiS77yR77yVIOODn-ODhOODleesrO-8k-ODk-ODqw!5e0!3m2!1sja!2sjp!4v1669963678949!5m2!1sja!2sjp"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </td>
      </tr>
      <tr>
        <th>TEL</th>
        <td>050-3636-5551</td>
      </tr>
      <tr>
        <th>FAX</th>
        <td>06-6920-1611</td>
      </tr>
      <tr>
        <th>E-MAIL</th>
        <td>
          <a href="mailto:info@office-zero.co.jp">info@office-zero.co.jp</a>
        </td>
      </tr>
    </tbody>
  </table>

  <h2>メールフォーム</h2>
  <form name="myform" action="/sendmail.php" method="post">
    <table class="t_text">
      <tr>
        <th>お名前</th>
        <td>
          <input
            id="name"
            name="name"
            type="text"
            size="35"
            v-model="mailName"
          />
        </td>
      </tr>
      <tr>
        <th>Mail</th>
        <td>
          <input
            id="email"
            name="email"
            type="text"
            size="35"
            v-model="mailAddress1"
          /><br />※こちらに返信いたします。
        </td>
      </tr>
      <tr>
        <th>Mail確認</th>
        <td>
          <input
            id="email_2"
            name="E-Mail（確認用）"
            type="text"
            size="35"
            v-model="mailAddress2"
          /><br />
          ※確認用に上記と同じアドレスをもう一度入力して下さい。
        </td>
      </tr>
      <tr>
        <th>お問い合わせ</th>
        <td>
          <textarea
            rows="8"
            id="con"
            name="con"
            cols="40"
            v-model="mailBody"
          ></textarea>
        </td>
      </tr>
      <tr>
        <td></td>
        <td>
          <input
            class="inline-block"
            type="button"
            value="確認画面へ"
            v-on:click="checkData"
          />&nbsp;&nbsp;
          <input
            class="inline-block"
            type="button"
            value="リセット"
            v-on:click="resetAll"
          />
          <!---->
          <div id="overlay" v-show="modalShow">
            <div id="content">
              <h2>送信内容の確認</h2>
              お名前:<br />
              {{ mailName }}<br /><br />
              Mail: <br />
              {{ mailAddress1 }}<br /><br />
              お問い合わせ内容:<br />
              {{ mailBody }}<br /><br />
              <input
                class="inline-block"
                type="button"
                value="メールを送信する"
                v-on:click="sendmail"
              />&nbsp;&nbsp;
              <input
                class="inline-block"
                type="button"
                value="キャンセル"
                v-on:click="modalHidden"
              />
            </div>
          </div>
        </td>
      </tr>
    </table>
  </form>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
export default {
  name: "AccessPage",
  props: {
    msg: String,
  },
  setup() {
    const mailName = ref("");
    const mailAddress1 = ref("");
    const mailAddress2 = ref("");
    const mailBody = ref("");
    const modalShow = ref(false);

    function checkData() {
      let errorMessaeg = "";
      if (mailName.value == undefined || mailName.value === "")
        errorMessaeg += "名前が入力されていません\n";
      if (mailAddress1.value == undefined || mailAddress1.value === "")
        errorMessaeg += "メールアドレス1が入力されていません\n";
      if (mailAddress2.value == undefined || mailAddress2.value === "")
        errorMessaeg += "メールアドレス2が入力されていません\n";
      if (mailBody.value == undefined || mailBody.value === "")
        errorMessaeg += "本文が入力されていません\n";

      if (errorMessaeg === "") {
        // メールチェック
        const ml = /.+@.+/;
        if (!mailAddress1.value.match(ml)) {
          alert("メールアドレスが不正です");
          return;
        }

        if (document.myform.email.value != document.myform.email_2.value) {
          alert("メールアドレスが一致しません。");
          return;
        } else {
          // 入力値が妥当なので、メール送信してよいかの最終確認を行う
          modalShow.value = true;
        }
      } else {
        // 入力漏れがある場合
        alert(errorMessaeg);
      }
    }

    function sendmail() {
      const params = new URLSearchParams();
      params.append("name", mailName.value);
      params.append("email", mailAddress1.value);
      params.append("con", mailBody.value);
      params.append("flg", "true");

      axios
        .post("/sendmail.php", params)
        .then((result) => {
          console.log(result);
          alert("メールを送信しました");
          resetAll();
        })
        .catch((error) => {
          console.log(error);
          alert("メール送信に失敗しました");
        })
        .finally(() => {
          modalShow.value = false;
        });
    }

    function resetAll() {
      mailAddress1.value = "";
      mailAddress2.value = "";
      mailName.value = "";
      mailBody.value = "";
    }

    function modalHidden() {
      modalShow.value = false;
    }

    return {
      checkData,
      resetAll,
      sendmail,
      modalHidden,
      mailName,
      mailAddress1,
      mailAddress2,
      mailBody,
      modalShow,
    };
  },
};
</script>
<style scoped>
.inline-block {
  display: inline-block;
}
.map {
  width: 800px;
  height: 450px;
}
@media (max-width: 950px) {
  .map {
    width: 100vw;
    height: 50vh;
  }
}

/* メール送信内容確認画面 */
#overlay {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
#content {
  z-index: 2;
  width: 50%;
  padding: 1em;
  background: #fff;
}
</style>