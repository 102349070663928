<template>
  <table>
    <tbody>
      <tr>
        <td>
          <a ref="mail"><h2>メール配信</h2></a>
        </td>
      </tr>
      <tr>
        <td>
          メール配信サービスとは登録されたメールアドレスへ一度にメールを配信するサービスです。<br />
          登録料は無料でご利用いただけます。<br />
          ご利用金額は、月額10,000円となっております。<br />
          （無料体験プランもご利用いただけます。<br />
          &nbsp;※無料体験プランは一部機能がご利用いただけません。予めご了承くださいませ。）<br />
          <br />
          ■オプション<br />
          ①配信時間設定<br />
          &nbsp;メールを配信する時間を任意（分単位）で設定できます。<br />
          ②顧客区分別送信<br />
          &nbsp;登録されているメールアドレスに区分を持たせているため、<br />
          &nbsp;送信を行うメールアドレスと送信を行わないメールと区別してメールを配信することが可能です。<br />
          &nbsp;（区分は様々に分けております。生年月日、性別、登録日、客層、アンケート・・などで送信先を選択できます。）<br />
          ③メールアドレスをCSVファイルより一括で登録することが可能です。<br />
          ④WEBよりマニュアルをご参照いただくことが可能です。<br />
          ⑤データのバックアップ。<br />
          &nbsp;（弊社にてバックアップをとっておりますので、お客様で面倒な作業の必要はありません。）<br />
          <br />
          ■ご利用方法<br />
          インターネットをご利用いただけるパソコン一台あれば、どこからでもご利用可能です。<br />
          弊社よりお客様へお知らせいたしますURLからサービスをご利用いただけます。<br />
          <br />
          ■ご利用までの手順<br />
          ①弊社までサービスのご利用をTELかメールにてお申し込みください。<br />
          ②弊社よりお客様へID・パスワード・URLを発行いたします。<br />
          ③URLへアクセスしていただき、ID・パスワードを入力していただくことでご利用可能となります。<br />
        </td>
      </tr>
      <tr>
        <td>
          <a ref="rental"><h2>レンタルサーバー</h2></a>
        </td>
      </tr>
      <tr>
        <td>
          弊社で管理しておりますサーバーのレンタルをいたしております。<br />
          レンタル内容といたしましては、可能な限りお客様のご要望にお応えいたします。（ＨＤ容量の制限など）<br />
          レンタル価格はレンタル内容により変動いたします。<br />
          <br />
          レンタルしておりますサーバーのOSはRed Hat Linuxとなります。<br />
          <br />
          （注：法人対象です。）
        </td>
      </tr>
      <tr>
        <td>
          <a ref="domain"><h2>ドメイン取得代行</h2></a>
        </td>
      </tr>
      <tr>
        <td>
          お客様が任意のドメインを取得される際、弊社が代行し取得を行うサービスです。<br />
          取得価格は第一レベルドメインの種類によって変動いたします。<br />
          <br />
          ■ドメインとは<br />
          &nbsp;[http://www.office-zero.co.jp]でいえば[office-zero.co.jp]を指します。<br />
          &nbsp;<br />
          &nbsp;[office-zero]&nbsp;・・・第２レベルドメイン<br />
          &nbsp;[co.jp]&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;・・・第１レベルドメイン<br />
          <br />
          &nbsp;ドメインを取得することにより[○○○@office-zero.co.jp]のメールアドレスを使用することが可能となります。<br />
          <br />
          &nbsp;(注１)<br />
          &nbsp;上記は例です。<br />
          &nbsp;お客様には第２レベルドメイン・第１レベルドメインをご自由に決定していただきます。<br /><br />
          &nbsp;(注２)<br />
          &nbsp;任意のドメインは他者により既に登録されている場合がございます。<br />
          &nbsp;予めご了承くださいませ。<br />
        </td>
      </tr>
    </tbody>
  </table>
  <br />
  <br />
</template>

<script>
import { onMounted, nextTick, ref } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "ServicePage",
  props: {
    msg: String,
  },
  setup() {
    // VueRouterを使用する
    const route = useRoute();
    // VueRouterからハッシュコードを取得する
    const hash = route.hash;

    // templateに設定している参照オブジェクトを列挙する
    const refs = {
      mail: ref(),
      rental: ref(),
      domain: ref(),
    };

    /**
     * マウントされたときに呼び出されるメソッド
     */
    onMounted(() => {
      nextTick(function () {
        if (hash) {
          const refName = hash.replace("#", "");
          scrollToAnchorPoint(refName);
        }
      });
    });

    /**
     * なめらかに自動遷移する処理
     * @param {*} refName
     */
    const scrollToAnchorPoint = (refName) => {
      let el = null;
      switch (refName) {
        case "mail":
          el = refs.mail.value;
          break;
        case "rental":
          el = refs.rental.value;
          break;
        case "domain":
          el = refs.domain.value;
          break;
      }
      if (el != undefined) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    };

    return {
      ...refs,
      //scrollToAnchorPoint,
    };
  },
};
</script>