import { createRouter, createWebHistory } from 'vue-router';
import Index from './components/Top.vue';
import About from './components/About.vue';
import Service from './components/Service.vue';
import Recruit from './components/Recruit.vue';
import Privacy from './components/Privacy.vue';
import Access from './components/Access.vue';
import NotFound from './components/NotFound.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/top' },
    { name: 'top', path: '/top', component: Index },
    { name: 'about', path: '/about', component: About },
    { name: 'service', path: '/service', component: Service },
    { name: 'recruit', path: '/recruit', component: Recruit },
    { name: 'privacy', path: '/privacy', component: Privacy },
    { name: 'access', path: '/access', component: Access },
    { path: '/:notFound(.*)', component: NotFound }
  ],
});

export default router;